/* eslint-disable no-unused-vars */
import Vue from "vue";
import Vuex from "vuex";
import Hashids from "hashids";
import axios from "axios";
import moment from "moment";
// modules
import chatStore from "./modules/chatStore";
import userStore from "./modules/userStore";
import windowStore from "./modules/windowStore";
import styleStore from "./modules/styleStore";
import otherStore from "./modules/otherStore";

import { OMNICHANNEL_URL } from "@/utils/variables";

Vue.use(Vuex);
Vuex.Store.prototype.$axios = axios;

let hashids = new Hashids("", 6);
// import "../../public/lenna-init"

export default new Vuex.Store({
  state: {
    // appId: { ...lennaInit.appId }
    appId: localStorage.lenna_initialize,
    omniBaseUrl: process.env.VUE_APP_PUBLIC_URL
  },
  getters: {
    appId(state) {
      if (state.appId) {
        return JSON.parse(state.appId).appId;
      }
    },
    decodedAppId(state) {
      return hashids.decode(JSON.parse(state.appId).appId.hashed);
    },
    omniBaseUrl(state) {
      return state.omniBaseUrl;
    }
  },
  mutations: {},
  modules: {
    window: windowStore,
    chat: chatStore,
    user: userStore,
    style: styleStore,
    other: otherStore
  }
});
