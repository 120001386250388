var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.quickButtonList),expression:"quickButtonList"}],staticClass:"lenna-message lenna-d-flex lenna-flex-wrap lenna-self lenna-custom-flex-wrap",staticStyle:{"top":"0px","margin-left":"-24px"}},_vm._l((_vm.quickButtonList),function(quickbtn,key){return _c('div',{key:key,staticClass:"lenna-m-1"},[(_vm.marquee(quickbtn))?_c('div',{staticClass:"lenna-pointer lenna-px-2 lenna-py-2 lenna-quickbutton-wrapper lenna-custom-rounded-border lenna-box-shadow custom-scrollbar",class:{
        'marquee-full-width': _vm.checkMarquee(key),
        'marquee-fit-width': !_vm.checkMarquee(key)
      },style:({
        'overflow-x': _vm.setOverflow(quickbtn)
      }),on:{"click":function($event){return _vm.quickButtonClick(quickbtn)},"mouseover":function($event){return _vm.showMarquee(true, key)},"mouseleave":function($event){return _vm.showMarquee(false, key)}}},[_c('div',{staticClass:"lenna-quickbutton-item"},[(_vm.checkMarquee(key))?_c('marquee-text',{key:key,attrs:{"paused":_vm.paused(quickbtn),"repeat":1,"duration":6}},[_vm._v("\n          "+_vm._s(quickbtn)+"\n        ")]):_vm._e(),(!_vm.checkMarquee(key))?_c('div',{key:key},[_vm._v("\n          "+_vm._s(_vm.truncateText(quickbtn))+"\n        ")]):_vm._e()],1)]):_c('div',{staticClass:"lenna-pointer lenna-px-2 lenna-py-2 lenna-quickbutton-wrapper lenna-custom-rounded-border lenna-box-shadow",style:({
        width: 'fit-content',
        'overflow-x': 'hidden'
      }),on:{"click":function($event){return _vm.quickButtonClick(quickbtn)}}},[_c('div',{staticClass:"lenna-quickbutton-item"},[_c('div',{key:key},[_vm._v("\n          "+_vm._s(quickbtn)+"\n        ")])])])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }