<template>
  <div class="lenna-register-main lenna-card-body">
    <form class="lenna-form-lenna" @submit.prevent="submitHandler">
      <div class="lenna-form-group">
        <label class="lenna-text-muted">
          <b>Percakapan anda telah berakhir</b>
          silakan muat ulang halaman dengan klik tombol di bawah ini, anda dapat
          menggunakan email yang sama dengan sebelumnya jika ingin menggunakan
          layanan {{ style.app ? style.app.name : "Lenna" }} Chat ini.
        </label>
      </div>
      <button
        type="submit"
        @click.prevent="submitHandler"
        class="lenna-btn lenna-btn-block lenna-bg-dark-blue lenna-capsule pulse"
      >
        Muat Ulang
      </button>
    </form>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      style: "style/getStyle"
    })
  },
  methods: {
    submitHandler() {
      window.location.reload();
    }
  }
};
</script>
<style lang="scss" scoped>
.lenna-register-main {
  height: 65vh;
  background: #f8f8f8 !important;
}
.pulse {
  :focus {
    outline: none !important;
  }
  animation: pulse-color 1s infinite;
  -webkit-animation: pulse-color 1s infinite;
}
@-webkit-keyframes pulse-color {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 52, 115, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(0, 52, 115, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 52, 115, 0);
  }
}

@keyframes pulse-color {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 52, 115, 0.4);
    box-shadow: 0 0 0 0 rgba(0, 52, 115, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(0, 52, 115, 0);
    box-shadow: 0 0 0 10px rgba(0, 52, 115, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 52, 115, 0);
    box-shadow: 0 0 0 0 rgba(0, 52, 115, 0);
  }
}
</style>
