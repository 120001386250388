<template>
  <div
    class="lenna-message lenna-d-flex"
    :class="{ 'lenna-self': isSelf, 'lenna-others': !isSelf }"
    v-show="false"
  >
    <message-avatar :isSelf="isSelf" />
    <div class="lenna-message-container" style="left:0px; top:0px;">
      <!-- <message-head :isSelf="isSelf" :message="message"/> -->
      <div
        class="lenna-message-content custom-message-content"
        :style="{
          textAlign: '-webkit-center',
          background: isSelf ? style.bubble_self : style.bubble_other,
          color: isSelf ? style.text_self : style.text_other
        }"
      >
        <div id="wave">
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import messageTypeMixin from "../mixins/messageTypeMixin";
import { mapGetters } from "vuex";
export default {
  mixins: [messageTypeMixin],
  computed: {
    ...mapGetters({
      style: "style/getStyle"
    })
  }
};
</script>
<style lang="scss" scoped>
.lenna-message {
  padding: 5px 10px;
}
.lenna-message-content {
  word-break: break-all;
  padding: 10px;
  // color: $dark;
  // background: $light-gray;
  border-radius: 10px 10px 10px 0;
  & img {
    width: 30px;
  }
}
.lenna-message.lenna-self {
  //   max-width: 80%;
  flex-direction: row-reverse;

  .lenna-message-content {
    text-align: right;
    // color: white;
    // background: $dark-blue;
    border-radius: 10px 10px 0 10px;
  }
}
.custom-message-content {
  background: transparent !important;
}
div#wave {
	position:relative;
	text-align:center;
	width:50px;
	height:auto;
	margin-left: auto;
	margin-right: auto;
	.dot {
		display:inline-block;
		width:9px;
		height:9px;
		border-radius:50%;
		margin-right:3px;
		background:#BEBBBB;
		animation: wave 1s linear infinite;

		&:nth-child(2) {
			animation-delay: -.9s;
		}

		&:nth-child(3) {
			animation-delay: -.6s;
		}
	}
}

@keyframes wave {
	0%, 60%, 100% {
		transform: initial;
	}

	30% {
		transform: translateY(-8px);
	}
}

// dot pulse
.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9999px 0 0 -5px #9880ff;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: .25s;
}

.dot-pulse::before, .dot-pulse::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px #9880ff;
  animation: dotPulseBefore .5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px #9880ff;
  animation: dotPulseAfter .5s infinite linear;
  animation-delay: .5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #9880ff;
  }
  30% {
    box-shadow: 9984px 0 0 2px #9880ff;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #9880ff;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #9880ff;
  }
  30% {
    box-shadow: 9999px 0 0 2px #9880ff;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #9880ff;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #9880ff;
  }
  30% {
    box-shadow: 10014px 0 0 2px #9880ff;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #9880ff;
  }
}
// end dot pulse

// dot carousel
.dot-carousel {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9984px 0 0 0 #9880ff, 9999px 0 0 0 #9880ff, 10014px 0 0 0 #9880ff;
  animation: dotCarousel 1.5s infinite linear;
}

@keyframes dotCarousel {
  0% {
    box-shadow: 9984px 0 0 -1px #9880ff, 9999px 0 0 1px #9880ff, 10014px 0 0 -1px #9880ff;
  }
  50% {
    box-shadow: 10014px 0 0 -1px #9880ff, 9984px 0 0 -1px #9880ff, 9999px 0 0 1px #9880ff;
  }
  100% {
    box-shadow: 9999px 0 0 1px #9880ff, 10014px 0 0 -1px #9880ff, 9984px 0 0 -1px #9880ff;
  }
}
// dot carousel

// dot elastic
.dot-elastic {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotElastic 1s infinite linear;
}

.dot-elastic::before, .dot-elastic::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-elastic::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotElasticBefore 1s infinite linear;
}

.dot-elastic::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotElasticAfter 1s infinite linear;
}

@keyframes dotElasticBefore {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1.5);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotElastic {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 1.5);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotElasticAfter {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
// dot elastic
</style>
