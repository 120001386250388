<template>
  <div
    class="lenna-message lenna-d-flex "
    :class="{ 'lenna-self': isSelf, 'lenna-others': !isSelf }"
  >
    <message-avatar :isSelf="isSelf" :message="message" />
    <div class="lenna-message-container message__image" style="left:0px; top:0px;">
      <!-- <message-head
        class="lenna-pl-2 lenna-pb-0"
        :isSelf="isSelf"
        :message="message"
      /> -->
      <div
        class="lenna-message-content lenna-d-flex lenna-flex-column"
        :class="{ 'lenna-message-content-me': isSelf, 'lenna-single-image': true }"
        :style="{
          'background-color': isSelf ? style.bubble_self : style.bubble_other,
          color: isSelf ? style.text_self : style.text_other,
          '--color': isSelf ? style.bubble_self : style.bubble_other
        }"
      >
      <!-- <div
        class="lenna-message-content lenna-d-flex lenna-flex-column lenna-image-content"
        :class="{ 'lenna-message-content-me': isSelf }"
        style="background: red;"
      > -->
        <template>
          <div style="display: flex; justify-content: center;">
            <div>
              <img
                v-viewer
                v-lazy="content.previewImageUrl"
                class="lenna-message-image lenna-box-shadow"
                style="border-radius: 8px; justify-content: center;"
                @click.prevent="detail(`${content.previewImageUrl}`)"
              />
            </div>
            <!-- <div v-if="contentImage.length >= 2">
              <img
                v-viewer
                v-lazy="contentImage[1].previewImageUrl"
                class="lenna-message-image lenna-box-shadow"
                @click.prevent="detail(`${contentImage[1].previewImageUrl}`)"
                style="border-radius: 8px;"
              />
            </div> -->
          </div>
          <!-- <template v-if="contentImage.length > 2">
            <div style="display: flex; justify-content: space-between; margin-top: 4px;">
              <div>
                <img
                  v-viewer
                  v-lazy="contentImage[2].previewImageUrl"
                  class="lenna-message-image lenna-box-shadow"
                  @click.prevent="detail(`${contentImage[2].previewImageUrl}`)"
                  style="border-radius: 8px;"
                />
              </div>
              <template v-if="contentImage.length > 3">
                <div>
                  <template v-if="contentImage.length > 4">
                    <div
                      class="lenna-image-overlay"
                      :style="{
                        'background': `linear-gradient(RGBA(17,47,51,0.5), RGBA(17,47,51,0.5)), url(${contentImage[3].previewImageUrl})`,
                        'width': '140px',
                        'height': '140px'
                      }"
                    >
                      <p>+ {{ (contentImage.length - 4) }}</p>
                    </div>
                  </template>
                  <template v-else>
                    <img
                      v-viewer
                      v-lazy="contentImage[3].previewImageUrl"
                      class="lenna-message-image lenna-box-shadow"
                      @click.prevent="detail(`${contentImage[3].previewImageUrl}`)"
                      style="border-radius: 8px;"
                    />
                  </template>
                </div>
              </template>
            </div>
          </template> -->
        </template>
      </div>
      <!-- <message-footer
        class="lenna-mt-2 lenna-custom-html-footer"
        :isSelf="isSelf"
        :message="message"
      /> -->
    </div>
  </div>
</template>
<script>
import messageTypeMixin from "../mixins/messageTypeMixin";
import { mapGetters } from "vuex";
export default {
  mixins: [messageTypeMixin],
  props: ["contentImage"],
  computed: {
    ...mapGetters({
      style: "style/getStyle"
    })
  },
  methods: {
    detail(link) {
      console.log(link);
      this.$store.dispatch("chat/toggleView", {
        file: link,
        status: true,
        isFull: false
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.message__image {
  border-radius: 10px 10px 10px 0;
}
.lenna-message {
  padding: 5px 10px;
}
.lenna-single-image {
  max-width: fit-content !important;
  width: fit-content !important;
}
.lenna-message-image {
  width: 300px;
  object-fit: cover;
}
.lenna-message.lenna-self {
  //   max-width: 80%;
  flex-direction: row-reverse;
}
.lenna-message-content {
  // padding: 10px;
  margin-right: unset;
  width: 300px;
  max-width: 350px !important;
  padding: 8px !important;
}
.lenna-message-content-me {
  border-radius: 10px 10px 0px 10px !important;
}
.lenna-image-overlay {
  text-align: center;
  border-radius: 8px;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  color: #fff;
  font-size: 20px;
  p {
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: relative;
    font-family: 'roboto-medium';
  }
}
</style>
