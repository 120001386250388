<template>
  <div class="lenna-message-avatar" :class="{ 'lenna-self': isSelf }">
    <img
      :src="style.avatar"
      class="lenna-rounded-circle lenna-align-items-start"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["isSelf", "message"],
  computed: {
    ...mapGetters({
      style: "style/getStyle"
    })
  }
};
</script>
<style lang="scss" scoped>
.lenna-message-avatar {
  margin-right: 0.6rem;
  img {
    width: 35px;
  }
}
.lenna-message-avatar.lenna-self {
  display: none;
}
</style>
