var _ = require("lodash");
import CryptoJS from "crypto-js";
const INIT_VECTOR = "0000000000000000";
class Encryptor {
  encrypt(plainText, secretLey = "k384y0r4nTRIANGLE") {
    let secretKey = _.padEnd(secretLey, 32, 0);
    let key = CryptoJS.enc.Utf8.parse(secretKey);
    let iv = CryptoJS.enc.Utf8.parse(INIT_VECTOR);
    let chiper = CryptoJS.AES.encrypt(plainText, key, {
      keySize: 256,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    let output = chiper.ciphertext.toString(CryptoJS.enc.Base64);
    return output;
  }
  // decrypt(encryptText, secretLey = "k384y0r4nTRIANGLE") {
  //   let secretKey = _.padEnd(secretLey, 32, 0);
  //   let key = CryptoJS.enc.Utf8.parse(secretKey);
  //   let iv = CryptoJS.enc.Utf8.parse(INIT_VECTOR);
  //   let output = chiper.ciphertext.toString(CryptoJS.enc.Base64);
  //   let chiper = CryptoJS.AES.encrypt(plainText, key, {
  //     keySize: 256,
  //     iv: iv,
  //     mode: CryptoJS.mode.CBC,
  //     padding: CryptoJS.pad.Pkcs7
  //   });
  //   return output;
  // }
}
export default new Encryptor();
