<template>
  <div
    class="lenna-message lenna-d-flex lenna-system"
    v-if="queue !== null ? queue.state : false"
  >
    <!-- <message-avatar :isSelf="isSelf" /> -->
    <div class="lenna-message-container lenna-mx-auto" style="left:0px; top:0px;">
      <!-- <message-head :isSelf="isSelf" :message="message"/> -->
      <div class="lenna-message-content lenna-d-flex">
        <div
          class="lenna-spinner-grow lenna-spinner-grow-sm lenna-mr-2"
          role="status"
        >
          <span class="lenna-sr-only">Loading...</span>
        </div>
        <span
          v-if="queue.index !== 0"
          class="lenna-badge lenna-badge-primary lenna-px-2 lenna-py-1"
          style="background-color: rgb(0, 50, 117);"
        >
          Added to queue (#{{ queue.index }}).
          <small v-if="showEstimate">
            Estimate waiting time is {{ estimate }} seconds
          </small>
        </span>
        <span
          v-else
          class="lenna-badge lenna-badge-primary lenna-px-2 lenna-py-1"
          style="background-color: rgb(0, 50, 117);"
        >
          Please wait....
          <small v-if="showEstimate">
            Estimate waiting time is {{ estimate }} seconds
          </small>
        </span>
        <!-- <img
          src="@/assets/images/icons/typing.gif"
          class="lenna-d-block lenna-box-shadow"
        /> -->
      </div>
    </div>
  </div>
</template>
<script>
import messageTypeMixin from "../mixins/messageTypeMixin";
import { mapGetters } from "vuex";
import { subtract } from "lodash";
export default {
  mixins: [messageTypeMixin],
  data() {
    return {
      showEstimate: true,
      estimate: 0
    };
  },
  computed: {
    ...mapGetters({
      style: "style/getStyle",
      queue: "chat/getQueueData",
      queueParam: "chat/queueParam",
      estimateQueue: "chat/getEstimateQueue"
    })
  },
  watch: {
    queue: {
      deep: true,
      immediate: true,
      handler() {
        // console.log("watch queue", newVal);
        this.countDownEstimate();
      }
    },
    estimateQueue: {
      deep: true,
      immediate: true,
      handler(newVal) {
        // console.log("estimateQueue", newVal);
        this.estimate = newVal;
      }
    }
  },
  methods: {
    countDownEstimate() {
      // console.log("countDownEstimate", this.estimate);
      if (this.estimate > 0) {
        this.showEstimate = true;
        setTimeout(() => {
          this.estimate = subtract(this.estimate, 1);
          this.countDownEstimate();
        }, 1000);
      }
      if (this.estimate <= 0) {
        this.showEstimate = false;
      }
    }
  },
  mounted() {
    this.countDownEstimate();
  }
};
</script>
<style lang="scss" scoped>
.lenna-message {
  padding: 5px 10px;
}
.lenna-message-content {
  word-break: break-all;
  padding: 10px;
  background: transparent;
  border-radius: 10px 10px 10px 0;
  & img {
    width: 30px;
  }
}
.lenna-message.lenna-system {
  flex-direction: row-reverse;
  .lenna-message-content {
    text-align: center;
    border-radius: 10px 10px 0 10px;
  }
}
</style>
