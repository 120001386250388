var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lenna-message lenna-d-flex lenna-others",class:{
    'lenna-others-block': !_vm.isSelf
  }},[_c('div',{class:{
      'lenna-d-flex lenna-custom-header': !_vm.isSelf
    }},[_c('message-avatar',{attrs:{"isSelf":_vm.isSelf,"message":_vm.message}}),_c('message-head',{attrs:{"isSelf":_vm.isSelf,"message":_vm.message}})],1),_c('div',{staticClass:"lenna-message-container",staticStyle:{"left":"0px","top":"0px"}},[_c('div',{staticClass:"lenna-message-content lenna-d-flex",style:({
        'background-color': _vm.isSelf ? _vm.style.bubble_self : _vm.style.bubble_other,
        color: _vm.isSelf ? _vm.style.text_self : _vm.style.text_other,
        '--color': _vm.isSelf ? _vm.style.bubble_self : _vm.style.bubble_other
      })},[_c('div',{staticClass:"lenna-text-content"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.content.content)}})])]),_c('message-footer',{staticClass:"lenna-custom-html-footer",attrs:{"isSelf":_vm.isSelf,"message":_vm.message}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }