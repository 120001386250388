<template>
  <div
    class="lenna-message lenna-d-flex"
    :class="{ 'lenna-self': isSelf, 'lenna-others': !isSelf, 'lenna-others-block': !isSelf, }"
    v-show="false"
  >
    <div
        :class="{
          'lenna-d-flex lenna-custom-header': !isSelf,
        }"
    >
      <message-avatar :isSelf="isSelf" :message="message" />
      <message-head :isSelf="isSelf" :message="message" />
    </div>
    <div class="lenna-message-container" style="left:0px; top:0px;">
      <div class="lenna-message-content lenna-custom lenna-custom-summary-content table-responsive">
        <table
          class="lenna-table lenna-bg-white summary-table lenna-box-shadow lenna-custom-table"
        >
          <thead>
            <tr>
              <th class="lenna-text-center text-summary" style="padding-bottom:10px; color:white;" colspan="2">
                {{ content.title || "Summary" }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="summary-tr" v-for="(row, index) in content.columns" :key="index">
              <td class="summary-td">{{ row.field }}</td>
              <td class="summary-td">{{ row.value }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import messageTypeMixin from "../mixins/messageTypeMixin";
export default {
  mixins: [messageTypeMixin]
};
</script>
<style lang="scss" scoped>
.lenna-message {
  padding: 5px 10px;
}
.lenna-message.lenna-self {
  //   max-width: 80%;
  flex-direction: row-reverse;

  .lenna-message-content {
    text-align: right;
    color: white;
    background: $dark-blue;
  }
}
.lenna-custom-summary-content {
  background: #FFF !important;
  padding: 8px !important;
  max-width: 291px !important;
}
.lenna-custom-table {
  margin-bottom: 0 !important;
  box-shadow: none !important;
  border-collapse:separate !important;
  border-spacing: 10px 10px !important;
  thead {
    background: #006885;
    color: #FFF;
    //font-family: 'Inter';
    line-height: .8;
    tr {
      border: none !important;
      th {
        font-size: 12px;
      }
    }
  }
  tbody {
    line-height: .8;
     background-color:white;
    tr {
      background: #F2F2F2;
      border: none !important;
      td {
        &:first-child {
          width: 100px !important;
          max-width: 100px !important;
          font-family: 'Roboto';
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        &:nth-child(2) {
          //width: 220px !important;
          //max-width: 160px !important;
          text-align: end;
          font-family: 'Roboto';
          overflow: hidden;
          text-align: end;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
.text-summary {
font-family: "roboto-light", sans-serif !important;
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 19px;
color: #FFFFFF;
}

.summary-table {
  background-color:#006885;
  border-radius: 10px;
  border:5px solid #006885;
}
.summary-tr {
   background:white;
   padding:10px;
}
.summary-td {
   padding:10px;
   background: #D5E9F1;
   border-radius:8px;
   font-family: "roboto-light", sans-serif !important;
   font-style: normal;
   font-weight: 600;
   font-size: 14px;
   line-height: 19px;
   color: #585757;
}
</style>
