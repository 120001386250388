<template>
    <div class="lenna-view-image">
        <div class="action">
            <div>
                <img src="@/assets/images/icons/leftarrow.png" class="action-view-image" alt="" @click.prevent="back">
            </div>
            <div>
                <img src="@/assets/images/icons/expand.png" class="action-view-image" @click.prevent="fullDetail(`${detailImage}`)" alt="">
            </div>
        </div>
        <div class="detail">
            <img :src="detailImage" class="image-detail" alt="">
        </div>
    </div>
</template>

<script>
export default {
    name: "ViewImage",
    computed: {

    },
    methods: {
        back() {
            this.$store.dispatch("chat/toggleView", { file: "", status: false, isFull: false });
        },
        fullDetail(path) {
            this.$store.dispatch("chat/toggleView", { file: path, status: false, isFull: true });
        }
    },
    computed: {
        detailImage() {
            return this.$store.getters["chat/detailImage"];
        }
    }
}
</script>

<style scoped>
.lenna-view-image {
    background: #000;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 10;
}
.action {
    display: flex;
    justify-content: space-between;
    padding: 24px 16px;
    z-index: 11 !important;
    position: absolute;
    top: 0;
    width: 100%;
}
.action-view-image {
    width: 24px;
    height: 24px;
}
.image-detail {
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    position: relative;
}
.detail {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
}
</style>