import moment from "moment";
import _ from "lodash";
// var _ = require("lodash");
class Formatter {
  currency(value) {
    let val = (value / 1).toFixed(0).replace(".", ",");
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  trimText(value, length = 20) {
    if (value.length > length) {
      return value.substr(0, length) + "..";
    }
    return value;
  }
  formatDate(date, format) {
    return moment(date).format(format);
  }
  filterEmptyObject(data) {
    _.mixin({
      compactObject: function(o) {
        _.each(o, function(v, k) {
          if (!v) {
            delete o[k];
          }
        });
        return o;
      }
    });
    let output = _.compactObject(data);
    return output;
  }
  convertObjectToSeparatedComma(data) {
    let output = _.map(data, function(value, key) {
      return `${key}:${value}`;
    });
    output = _.join(output, ", ");
    output = _.toString(output);
    return output;
  }
}
export default new Formatter();
