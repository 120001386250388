var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"lenna-message",class:{
    'lenna-self': _vm.isSelf,
    'lenna-others': !_vm.isSelf,
    'lenna-others-block': !_vm.isSelf,
    'lenna-d-flex': !_vm.checkIsObject(_vm.content.text)
  },style:({ display: _vm.checkIsObject(_vm.content.text) ? 'none' : '' })},[_c('div',{class:{
      'lenna-d-flex lenna-custom-header': !_vm.isSelf
    }},[_c('message-avatar',{attrs:{"isSelf":_vm.isSelf,"message":_vm.message}}),_c('message-head',{attrs:{"isSelf":_vm.isSelf,"message":_vm.message}})],1),_c('div',{staticClass:"lenna-message-container",staticStyle:{"left":"0px","top":"0px"}},[_c('div',{staticClass:"lenna-message-content",class:{
        handleClassAnimate: _vm.handleClassAnimate
      },style:({
        'background-color': _vm.isSelf ? _vm.style.bubble_self : _vm.style.bubble_other,
        color: _vm.isSelf ? 'red' : _vm.style.text_other,
        '--color': _vm.isSelf ? _vm.style.bubble_self : _vm.style.bubble_other
      }),attrs:{"id":_vm.handleId}},[_c('span',{staticClass:"lenna-text-content",style:({
          color: _vm.isSelf ? _vm.style.text_self : _vm.style.text_other
        })},[_vm._l((_vm.list_contents),function(each_content,index){return [(each_content.type == 'text')?_c('span',{key:index,staticStyle:{"font-family":"'roboto-regular' !important"},style:({
              color: _vm.isSelf ? _vm.style.text_self : _vm.style.text_other
            })},[_vm._v("\n            "+_vm._s(each_content.text)+"\n          ")]):_c('span',{key:index,staticClass:"link",style:({
              color: _vm.isSelf ? _vm.style.text_self : _vm.style.text_other
            }),on:{"click":function($event){return _vm.handleClickLink(each_content.text)}}},[_vm._v("\n            "+_vm._s(each_content.text)+"\n          ")])]})],2)]),_c('message-footer',{attrs:{"isSelf":_vm.isSelf,"message":_vm.message}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }