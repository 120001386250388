/* eslint-disable prettier/prettier */
import Vue from "vue";
import axios from "axios";
import io from "socket.io-client";
import Echo from "laravel-echo";
var moment = require("moment");
moment.locale("en");
import {Howl} from "howler";

window._ = require("lodash");
window.io = require("socket.io-client");
if (typeof io !== "undefined") {
  window.Echo = new Echo({
    broadcaster: "socket.io",
    host: process.env.VUE_APP_SOCKET_URL,
    // auth: {
    //   headers: {
    //       "Authorization": "Bearer " + "9ded7dd2fdf79d758526746545295912"
    //   }
    // }
  });
}

const send = new Howl({
  src: [require("@/assets/music/stairs.mp3")],
  volume: 0.5
});

const receive = new Howl({
  src: [require("@/assets/music/chinup.mp3")],
  volume: 0
});

const interceptor = axios.create({
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Access-Control-Allow-Origin': '*',
    'Accept': 'application/json',
  },
  timeout: 90000,
  baseURL: process.env.VUE_APP_OMNICHANNEL_URL
})
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
Vue.prototype.$moment   = moment;
Vue.prototype.$axios    = interceptor;
Vue.prototype._         = window._;
Vue.prototype.$socket   = io(process.env.VUE_APP_SOCKET_URL);
Vue.prototype.$echo     = window.Echo;
Vue.prototype.$send     = send;
Vue.prototype.$receive  = receive;
