/* eslint-disable prettier/prettier */
// import moment from "moment"
class LennaMessageType {
    constructor(input) {
        this.uploadUrl = `${process.env.VUE_APP_PUBLIC_URL}/upload/chat`

        this.message = {
            temporary_id: input.temporary_id,
            id: input.temporary_id,
            messageable_id: input.messageable_id,
            messageable_type: "user",
            created_at: null,
            // created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
        }
    }
    text(input) {
        let content = [
            {
                // show: true,
                type: "text",
                text: input.text || '',
                speech: input.text || '',
            }
        ]
        return {
            ...this.message,
            content: content
        }
    }
    image(input) {
        let content = [
            {
                // show: true,
                type: "image",
                // originalContentUrl: this.uploadUrl + "/" + input.fileName,
                // previewImageUrl: this.uploadUrl + "/" + input.fileName,
                originalContentUrl: input.fileUrl,
                previewImageUrl: input.fileUrl,
            }
        ]
        return {
            ...this.message,
            content: content
        }
    }
    file(input) {
        let content = [
            {
                // show: true,
                type: "file",
                // fileUrl: this.uploadUrl + "/" + input.fileName,
                fileUrl: input.fileUrl,
                fileName: input.fileName,
            }
        ]
        return {
            ...this.message,
            content: content
        }
    }
}
export default LennaMessageType
