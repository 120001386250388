export default {
  data() {
    return {
      baseUrl: "https://app.lenna.ai/webchat/"
    };
  },
  methods: {
    logx(data) {
      console.log(data);
    },
    imgErrorHandler(event) {
      event.target.src = require(`@/assets/images/logo_lenna_grey.png`);
    },
    asset(path) {
      return `${this.baseUrl}/${path}`;
    },
    parseJson(content) {
      return JSON.parse(content);
    }
  }
};
